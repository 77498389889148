import { IntroTech } from '@models/interfaces';

export enum PlayboxActions {
  CLOSE_I_CRAP_WARNING = 'CLOSE_I_CRAP_WARNING',
  CLOSE_INTRO = 'CLOSE_INTRO',
  CLOSE_PLAYBOX = 'CLOSE_PLAYBOX',
  RATING = 'RATING',
  TOGGLE_FULLSCREEN = 'TOGGLE_FULLSCREEN',
  TOGGLE_CRT = 'TOGGLE_CRT',
}

export enum PlayboxStateEnum {
  INACTIVE,
  LOAD,
  PLAY,
  WARN,
  RATE,
}

export type TPlayboxState = keyof typeof PlayboxStateEnum;

export const PlayboxSettingEnum = {
  crt: 'crt',
  fullscreen: 'fullscreen',
  icrap: 'icrap',
  forceVoting: 'forceVoting',
} as const;

export type TPlayboxSetting = keyof typeof PlayboxSettingEnum;
export type TPlayboxSettings = Record<TPlayboxSetting, boolean> & { tech?: IntroTech };
